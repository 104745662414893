$bg: #1C1C1D;

html, body {
  background-color: $bg;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

::selection {
  background: #5E5CE6;
}
::-moz-selection {
  background: #5E5CE6;
}

.bg {
  background-color: $bg;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.top-offset {
  margin-top: -50px;
}

.header {
  height: 70px;
}

.logo {
  font-family: 'Passion One', cursive;
  font-weight: 400;
  font-size: 24px;
  a {
    color: rgba(255, 255, 255, 1);
    &:hover {
      color: #fff;
    }
  }
}

.header-1 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.header-2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
}

.header-3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
}

.label {
  color: rgba(255, 255, 255, 0.25);
}

.check-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 5px;
  margin-right: 20px;
}

.header-content {
  justify-content: space-between;
}

a {
  font-weight: 600;
  color: #fff;
  &:hover {
    color: #0E179B;
  }
}

.main-menu {
  a {
    font-weight: 600;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.05);
  a {
    color: rgba(255, 255, 255, 1);
    &:hover {
      color: #0E179B;
    }
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media (min-width: 0px) and (max-width: 768px) {
  .bg {
    background-color: $bg;
    height: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .top-offset {
    margin-top: 70px;
  }
  .header-1 {
    font-size: 36px;
    line-height: 46px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
  }
}